<template>
  <v-container id="dashboard" fluid tag="section">
    <!-- {{$store.state.AdminInfo}} -->

    <template v-if="uid !== 24">
      <v-flex xs12 sm3 style="padding-right:30px">
         <v-select  v-model="gov_id.id" :items="govs" item-text="title" item-value="id" label=" المحافظة "
          required @change="gov_filter('trigger')" return-object persistent-hint></v-select>
      </v-flex>
    </template>

    <template v-else>
      <v-text-field class="mt-4" label="اكتب محافظه المتقدم او عنوانه" outlined @keyup.enter="search('trigger')" v-model="search_term">
        <v-btn v-if="is_search" color="#000" height="20px" width="20px" style="color:#fff" slot="append" @click="getJobs"> x </v-btn>
      </v-text-field>
    </template>
    

    <!-- <template v-for="sp in specs" >
      <v-btn @click="filter(sp.id, 'trigger')" :key="sp">{{sp.name}}</v-btn>
    </template>
     -->

    <v-layout row wrap style="padding:10px" v-if="uid !== 26">
      <v-flex xs12 sm3 style="padding-right:30px">

        <template v-if="uid === 25">
          <v-select  v-model="cat_filter_id.id" :items="deg" item-text="title" item-value="id" label="الدرجة العلمية"
          required @change="cats_filter('trigger')" return-object persistent-hint></v-select>
        </template>
        <template v-else>
          <v-select  v-model="cat_filter_id.id" :items="specs" item-text="name" item-value="id" label="الاختصاص"
          required @change="cats_filter('trigger')" return-object persistent-hint></v-select>
        </template>
        
      </v-flex>

      <v-flex xs12 sm3 style="padding-right:30px">
         <v-select  v-model="status_id.id" :items="status" item-text="name" item-value="id" label="حالة الطلب"
          required @change="cats_filter('trigger')" return-object persistent-hint></v-select>

      </v-flex>


      <v-flex xs12 sm6 style="padding-right:30px; display: flex; justify-content: flex-end;">
        <v-btn color="green" style="color:#fff" @click="getJobs()">الكل</v-btn>
      </v-flex>
    </v-layout>

    <v-data-table :headers="headers" :items="items"  class="elevation-1" :items-per-page="15">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title> المتقدمين على الوظيفة </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <!-- <v-spacer></v-spacer> -->


          <v-dialog v-model="dialog" max-width="890px">


            <v-toolbar color="primary" dark>معلومات المتقدم على الوظيفة</v-toolbar>

            <v-stepper>
              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-card class="mb-4" flat>
                    <v-container>
                      <v-row>
                        <v-col class=" text-start  " cols="12" sm="12" md="12">

                          <p class="infop">الاسم: <span style="font-weight: bold;">{{itemId.name}}</span></p>
                          <p class="infop">الهاتف: <span style="font-weight: bold;">{{PrePhone(itemId.phone)}}</span></p>
                          <p class="infop" v-if="uid === 25 || uid === 26">العنوان: <span style="font-weight: bold;">{{itemId.gov_title}}</span></p>
                          <p class="infop" v-else>العنوان: <span style="font-weight: bold;">{{itemId.address}}</span></p>
                          <p v-if="uid === 25" class="infop">الدرجة العلمية: <span style="font-weight: bold;">{{itemId.deg_title}}</span></p>
                          <p v-else-if="itemId.specialties_name" class="infop">التخصص: <span style="font-weight: bold;">{{itemId.specialties_name}}</span></p>
                          
                        </v-col>

                        <v-btn :href="'https://alkafeel.net/alkafeel_back_test/attached_cvs_mf4/'+itemId.cvs" v-if="itemId.cvs" target="_blank" color="primary">
                          تحميل السيرة الذاتية
                        </v-btn>

                        <v-btn class="mx-10" :href="'https://alkafeel.net/alkafeel_back_test/attached_cert_mf5/'+itemId.deg_file" v-if="itemId.deg_file" target="_blank" color="primary">
                           الشهادة
                        </v-btn>
                        
                        <!-- <div class="images">
                          <a data-fancybox="gallery" :href="'https://alkafeel.net/alkafeel_back_test/attached_cert_mf5/' + itemId.deg_file">
                            الشهادة
                          </a>
                        </div>
                         -->

                      </v-row>
                    </v-container>

                    <br><br>
                    <div class="images" v-if="preImgs(itemId.images)">
                      <a v-for="i in preImgs(itemId.images)" :key="i" data-fancybox="gallery" data-caption=""
                        :href="'https://alkafeel.net/alkafeel_back_test/attached_pic/' + i">
                        <img :src="'https://alkafeel.net/alkafeel_back_test/attached_pic/' + i" />
                      </a>
                      <br><br>
                    </div>


                  </v-card>

                  <v-btn  color="red" @click="dialog = false">
                    {{$t("close")}}
                  </v-btn>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-dialog>
        </v-toolbar>
      </template>


      <template v-slot:[`item.is_show`]="{ item }">
        <span class="is_show" :class="{ 'active' : item.is_show == 1 }" @click="unShow(item)"></span>
      </template>

    
      <template v-slot:[`item.actions`]="{ item }">

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <button class="ml-5" @click="show(item)" v-if="!item.isDeleted" v-bind="attrs" v-on="on">عرض </button>
          </template>
          <span>عرض </span>
        </v-tooltip>

      </template>


      <template v-slot:no-data>
        <v-btn color="primary" @click="getJobs">{{ $t("Reloading") }}</v-btn>
      </template>
    </v-data-table>



    <v-pagination class="pagination" total-visible="20" v-model="page"
      color="#c7000b" style="position: relative; top: 20px;" circle=""
      :length="pageCount">
    </v-pagination>


  </v-container>


</template>

<script>
  import axios from "axios";
  import {
    Fancybox
  } from "@fancyapps/ui";
  import "@fancyapps/ui/dist/fancybox.css";
  export default {
    name: "Jobs",
    data() {
      return {
        dialog: false,
        uid: 0,
       
        cat_filter_id: { id: 0 },
        status: [
          {
            id : '0',
            name : 'غير المقروء',
          },
          {
            id : '1',
            name : 'المقروء',
          },
          {
            id : '3',
            name : 'الكل',
          },
        ],
        
        status_id : { id : '3' },
        gov_id : {id : 0},
        current_page: 1,
        last_page: 0,
        page: 1,
        pageCount: 0,
        items: [],
        items: [],
        headers: [],
        itemId: [],

        search_term: null,
        is_search: false,

        specs: [],
        filter_id: null,
        is_filter: false,
        gover_filter: false,

        govs: [],
        deg: [],
      }
    },
    watch: {
      selected: 'search by sub_cat_id',
    },
    components: {},
    computed: {
      selected: function () {
        return this.getMoreitems();
      }
    },
    created() {
      this.auth();
      this.getJobs();
      this.filterSpec();
      this.get_govs();
      this.get_degress();
    },
    methods: {
      auth() {
        axios.get("/jops/checkAuth", {
        headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorizations: "Bearer " + this.$store.state.AdminInfo.token
            }
          }
        ).then(c => {
          if (c.data.success) {
            this.uid = c.data.data
            if (this.uid === 25) {
              this.headers = [{
                text: 'ت',
                value: 'id',
              },
              {
                text: 'الاسم',
                align: "start",
                value: "name"
              },
              {
                text: 'رقم الهاتف',
                align: "start",
                value: "phone"
              },
              {
                text: 'العنوان',
                align: "start",
                value: "gov_title"
              },
              {
                text: 'الدرجة العلمية',
                align: "start",
                value: "deg_title"
              },
              {text: 'الحالة',align: "start",value:"is_show"},
              {
                text: 'الاختيارات',
                value: "actions",
                sortable: false
              }]
            }
            
            else if (this.uid === 26) {
              this.headers = [{
                text: 'ت',
                value: 'id',
              },
              {
                text: 'الاسم',
                align: "start",
                value: "name"
              },
              {
                text: 'رقم الهاتف',
                align: "start",
                value: "phone"
              },
              {
                text: 'العنوان',
                align: "start",
                value: "gov_title"
              },
              // {
              //   text: 'الدرجة العلمية',
              //   align: "start",
              //   value: "deg_title"
              // },
              {text: 'الحالة',align: "start",value:"is_show"},
              {
                text: 'الاختيارات',
                value: "actions",
                sortable: false
              }]
            }
            
            else {
              this.headers = [{
                text: 'ت',
                value: 'id',
              },
              {
                text: 'الاسم',
                align: "start",
                value: "name"
              },
              {
                text: 'رقم الهاتف',
                align: "start",
                value: "phone"
              },
              {
                text: 'العنوان',
                align: "start",
                value: "address"
              },
              {
                text: 'التخصص',
                align: "start",
                value: "specialties_name"
              },
              {text: 'الحالة',align: "start",value:"is_show"},
              {
                text: 'الاختيارات',
                value: "actions",
                sortable: false
              }]
            }
          }
        }).catch( r => {
          if (r.response.status === 401 || r.response.status === 403) {
            // if token expired
            window.location = "/auth/"
          }
        }
          
        );
      },
      unShow (i) {
        if (i.is_show == 1) {
          i.is_show = 0;
          axios.post("/jops/unShow/"+i.id, {id:i.id}, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorizations: "Bearer " + this.$store.state.AdminInfo.token
            }
          })
        }
        
      },
      show(i) {
        //console.log(i);
        this.itemId = this.items[this.items.indexOf(i)];
        this.itemId.is_show = true;
        this.dialog = true;
        this.preImgs(i.images);
        axios.get("/jops/is_show/" + i.id, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorizations: "Bearer " + this.$store.state.AdminInfo.token
            }
          }).then(res => {
            //this.items = res.data.data.data;
            //console.log(res.data.data);
          })
          .catch(() => {});
      },
      getJobs() {
        
        this.search_term = null;
        this.is_filter = false;
        this.is_search = false;
        this.gover_filter = false;
        
        // reset filters params
        this.cat_filter_id = { id: 0 };
        this.status_id = { id : '3' };

        this.loading = true;
        axios.get("/jops/get_applicants_info?page=" + this.current_page, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorizations: "Bearer " + this.$store.state.AdminInfo.token
            }
          }).then(res => {
            this.loading = false;
            this.Oitems = res.data.data.data;
            this.Oitems.forEach((e, i) => {
              if (this.Oitems[i].phone) {
                this.Oitems[i].phone = this.Oitems[i].phone.replaceAll(' ', '');
              }
            });

            //const uniqueElementsBy = (arr, fn) => arr.reduce((acc, v) => { if (!acc.some(x => fn(v, x))) acc.push(v); return acc; }, []);
            //this.items= uniqueElementsBy(this.Oitems,(a, b) => a.phone == b.phone);


            this.items = this.Oitems;
            //console.log(items);
            this.last_page = res.data.data.last_page;
            this.pageCount = res.data.data.last_page;
          })
          .catch(() => {
            this.loading = false;
          });
      },
      getMoreitems() {
        if (this.current_page <= this.last_page) {
          this.current_page = this.page;
          if (this.is_search) {
            this.search('paging');
          } else if (this.is_filter) {
            //this.filter(this.filter_id, 'paging');
            this.cats_filter('paging');
          } else if (this.gover_filter) {
            this.gov_filter('paging');
          }else {
            this.getJobs();
          }

        }
      },
      iniFancy() {
        Fancybox.bind('[data-fancybox="gallery"]', {
          dragToClose: false,
          Toolbar: false,
          closeButton: "top",
          Image: {
            zoom: false,
          },
          on: {
            initCarousel: (fancybox) => {
              const slide = fancybox.Carousel.slides[fancybox.Carousel.page];
              fancybox.$container.style.setProperty("--bg-image", `url("${slide.$thumb.src}")`);
            },
            "Carousel.change": (fancybox, carousel, to, from) => {
              const slide = carousel.slides[to];
              fancybox.$container.style.setProperty("--bg-image", `url("${slide.$thumb.src}")`);
            },
          },
        });
      },
      preImgs(x) {
        if (x) {
          return JSON.parse(x);
          // var i = x.replaceAll('/', '').replaceAll('"', '').replaceAll('[', '').replaceAll(']', '').split(',');
          // if (i != 0) {return i;}
          // else {return null;}
          //console.log(i);
        }
        else {return null;}
      },
      PrePhone(x) {
        if (x) {
          return x.replaceAll(' ', '');
        }
      },
      filterSpec() {
        axios.get('jops/getrequired_specialties')
          .then(d => {
            this.specs = d.data.data
            this.specs.unshift({id:0,name:'الكل'})
          })
      },
      search(type) {
        this.is_filter = false;
        this.is_search = true;
        this.loading = true;

        // reset filters params
        this.cat_filter_id = { id: 0 };
        this.status_id = {id : '3' };

        if (type === 'trigger') {
          this.current_page = 1;
          this.page = 1;
        }
        axios.get('/jops/search/' + this.search_term + "?page=" + this.current_page,{
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorizations: "Bearer " + this.$store.state.AdminInfo.token
            }
          })
          .then(d => {
            this.loading = false;
            this.Oitems = d.data.data.data;
            this.Oitems.forEach((e, i) => {
              if (this.Oitems[i].phone) {
                this.Oitems[i].phone = this.Oitems[i].phone.replaceAll(' ', '');
              }
            });

            this.items = this.Oitems;
            this.last_page = d.data.data.last_page;
            this.pageCount = d.data.data.last_page;
          })
      },

      gov_filter (type) {
        this.is_filter = false;
        this.gover_filter = true;
        this.loading = true;

        if (this.gov_id.id.id === 0) {
          this.getJobs();
          return;
        }
        
        // reset filters params
        this.cat_filter_id = { id: 0 };
        this.status_id = {id : '3' };
        
        if (type === 'trigger') {
          this.current_page = 1;
          this.page = 1;
        }

        // axios.get('jops/filterByGov/' + this.cat_filter_id.id.id + "?page=" + this.current_page, {
        axios.get('jops/filterByGov/'+this.gov_id.id.id+'?page=' + this.current_page, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorizations: "Bearer " + this.$store.state.AdminInfo.token
            }
          })
          .then(d => {

            this.loading = false;
            // console.log(d.data.data.data);
            this.Oitems = d.data.data.data;
            this.Oitems.forEach((e, i) => {
              if (this.Oitems[i].phone) {
                this.Oitems[i].phone = this.Oitems[i].phone.replaceAll(' ', '');
              }
            });

            this.items = this.Oitems;
            this.last_page = d.data.data.last_page;
            this.pageCount = d.data.data.last_page;
            
          })
      },

      cats_filter(type) {
      // console.log(this.status_id.id);
        if (!this.status_id.id.id) {this.status_id.id = {id:'3'}} 
        this.search_term = null;
        this.is_search = false;
        this.gover_filter = false;
        this.filter_id = this.cat_filter_id.id.id;
        this.is_filter = true;
        this.loading = true;

        this.gov_id = {id : 0};
        // console.log('fid=>'+this.filter_id);
        if (type === 'trigger') {
          this.current_page = 1;
          this.page = 1;
        }
        // const AuthStr = 'Bearer ' + this.token;

        axios.get('jops/filterBySpecsId/' + this.cat_filter_id.id.id +'/'+ this.status_id.id.id + "?page=" + this.current_page, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorizations: "Bearer " + this.$store.state.AdminInfo.token
            }
          })
          .then(d => {
            // console.log(d.data, this.cat_filter_id.id.id, this.status_id.id.id );
            this.loading = false;
            this.Oitems = d.data.data.data;
            this.Oitems.forEach((e, i) => {
              if (this.Oitems[i].phone) {
                this.Oitems[i].phone = this.Oitems[i].phone.replaceAll(' ', '');
              }
            });

            this.items = this.Oitems;
            this.last_page = d.data.data.last_page;
            this.pageCount = d.data.data.last_page;

          })

        //    Fire.$emit('cats_fliter');
      },


      get_govs () {
          axios.get("jops/getGovs", {})
          .then(res => {
              this.govs = res.data;
              this.govs.unshift({id:0,title:'الكل'})
          })
      },
      get_degress () {
          axios.get("jops/getDegress", {})
          .then(res => {
              // console.log(res.data)
              this.deg = res.data;
              this.deg.unshift({id:0,title:'الكل'})
          })
      },


    },
  }
</script>

<style>
  .v-data-footer {
    display: none !important;
  }

  .images a {
    width: 200px;
    height: auto;
    display: inline-block;
    border-radius: 5px;
    margin: 10px;
  }

  .images a img {
    width: 100%;
    height: auto;
    border-radius: 5px;
  }

  .infop {
    font-size: 25px;
  }

  .pagination {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    display: block;
  }
  .is_show {
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: green;
  }
  .is_show.active {
    cursor: pointer;
    background-color: rgb(221, 221, 221) !important;
  }
</style>